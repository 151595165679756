import { get, post } from "./request";

const api = {};

// 页面数据
api.page = {
  home: (data) => get('/user/homeData', data)
}


export default api;
