import BigNumber from 'bignumber.js'
/* BigNumber解决js的以下问题
1、精度。
2、银行家算法。 */



// 加法
const plus = (arg1, arg2, type = 'num') => {
	var x = new BigNumber(arg1);
	var y = new BigNumber(arg2);

	let result = x.plus(y)

	if (type == 'str') {
		return result.toString();
	} else {
		return result.toNumber();
	}
}

// 减法
const minus = (arg1, arg2, type = 'num') => {
	var x = new BigNumber(arg1);
	var y = new BigNumber(arg2);

	let result = x.minus(y)

	if (type == 'str') {
		return result.toString();
	} else {
		return result.toNumber();
	}
}

// 乘法
const times = (arg1, arg2, type = 'num') => {
	var x = new BigNumber(arg1);
	var y = new BigNumber(arg2);

	let result = x.times(y)

	if (type == 'str') {
		return result.toString();
	} else {
		return result.toNumber();
	}
}

// 除法
const div = (arg1, arg2, type = 'num') => {
	var x = new BigNumber(arg1);
	var y = new BigNumber(arg2);

	let result = x.div(y)

	if (type == 'str') {
		return result.toString();
	} else {
		return result.toNumber();
	}
}

// 保留指定位数
const toFixed = (arg, num) => {
	var x = new BigNumber(arg);
	return x.toFixed(num);
}

// 保留指定位数（省略0）
const dp = (arg, num) => {
	var x = new BigNumber(arg);
	return x.dp(num);
}

// 保留指定位数 相邻数向下舍入（省略0）
const dpDown = (arg, num) => {
	let BN = BigNumber.clone();
	BN.config({
		ROUNDING_MODE: 5
	})

	return BN(arg).dp(num);
}

export default {
	plus,
	minus,
	times,
	div,
	toFixed,
	dp,
	dpDown
}