import axios from "axios";
import { Message } from "element-ui";
import { Loading } from "element-ui";
import router from "@/router";

let loadingInstace = "";

const http = axios.create({
  baseURL: window.SITE_CONFIG["apiURL"],
  timeout: 1000 * 8,
});

// 请求拦截
http.interceptors.request.use(
  (config) => {
    loadingInstace = Loading.service({ background: "rgba(38,50,56,.4)" });


    return config;
  },
  (error) => {
    loadingInstace.close();
    console.log(error, "request_error");
    Message.error(error.response.status + "_" + error.response.data.error);
    Message.error(error);
    return Promise.reject(error);
  }
);

// 相应拦截
http.interceptors.response.use(
  (res) => {

    Promise.reject(res);
  },
  (error) => {
    loadingInstace.close();
    const { response } = error;

    if (!response) return Message.error("您的网络断开了");

    Message.error(
      response.status + "_" + response.data.error + "  请向超管反馈"
    );

    return Promise.reject(error);
  }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */

export const get = (url, params) => {
  return http({
    url,
    params,
  });
};

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} data [请求时携带的参数]
 */
export const post = (url, data) => {
  return http({
    url,
    data,
    method: "post",
  });
};
