<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="less">
html,
body,
#app {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "PingFang SC";
  // font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
}
p {
  margin: 0;
}
div,
ul,
li,
span,
a {
  box-sizing: border-box;
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  color: #000;
}

.sui-content__wrapper,
.sui-sidebar,
.navbar__hearder,
.sidebar__inner,
.el-tabs__header {
  transition: inline-block 0.3s, left 0.3s, width 0.3s, margin-left 0.3s,
    font-size 0.3s;
}

.sui-sidebar ::v-deep {
  background-color: red;
  .el-menu-item {
    .menu-icon {
      font-size: 50px !important;
    }
    .span {
      font-size: 50px;
    }
  }
}

.seize {
  width: 100%;
  height: 20px;
  background-color: #f1f4f5;
}
</style>
