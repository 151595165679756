import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "@/router";
import store from "./store";
import http from './utils/request-api'
import cloneDeep from 'lodash/cloneDeep'
import * as echarts from "echarts"
import calc from '@/utils/calc.js'

Vue.use(ElementUI);

// function checkAndRestoreUrl() {
//     window.history.replaceState({}, '', '/');
// }
// // 在Vue实例创建前检查并恢复URL
// checkAndRestoreUrl();

Vue.prototype.$http = http
Vue.prototype.$echarts = echarts
Vue.prototype.$calc = calc

Vue.config.productionTip = false;

// 保存整站vuex本地储存初始状态
window.SITE_CONFIG['storeState'] = cloneDeep(store.state)

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
