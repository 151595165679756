import Vue from "vue";
import Vuex from "vuex";
import cloneDeep from "lodash/cloneDeep";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  modules: {

  },
  mutations: {
    // 重置vuex本地储存状态
    resetStore(state) {
      Object.keys(state).forEach((key) => {
        state[key] = cloneDeep(window.SITE_CONFIG["storeState"][key]);
      });
    },
  },
});
