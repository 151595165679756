import Vue from "vue";
import VueRouter from "vue-router";
import { Message } from "element-ui";
import { createLogger } from "vuex";
// window.SITE_CONFIG["menuList"] = require("../../public/menu.json");
var MENULIST = require("../../public/menu.json");

Vue.use(VueRouter);

const pageRoutes = [
  { path: "/", component: () => import("@/views/home"), name: "home" },
  { path: "/download", component: () => import("@/views/download"), name: "download" },
];

const router = new VueRouter({
  mode: "history",
  routes: pageRoutes,
});

router.beforeEach((to, from, next) => {

  return next();
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
